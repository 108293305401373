import logo from "./images/logo.png"
import "./App.css"
import * as React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { Button, CardActionArea, CardActions } from "@mui/material"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import chartLineSolid from "./images/chart-line-solid.svg"
import truckMovingSolid from "./images/truck-moving-solid.svg"
import * as adres from "./adresy"
import LogoutIcon from "@mui/icons-material/Logout"
import { Avatar } from "primereact/avatar"
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import { Toast } from "primereact/toast"

const port = adres.config.url

export default function App(props) {
  const toast = React.useRef(null)
  console.log(props.user)
  const user = props.user
  const grupy = props.user.groups
  const modules = [
    {
      name: "Protokół pojazdu CMS",
      link: "https://protokolcms.kociuk.pl",
      id: 1,
      allowedGroups: ["PROTOKOL_ROOT", "PROTOKOL_ADMIN", "PROTOKOL_USER"],
      description: "Moduł do zarządzania protokołami pojazdów",
      image: truckMovingSolid,
      alt: "ProtokolCMS",
    },
    {
      name: "KPI",
      link: "https://kpi.kociuk.pl",
      id: 2,
      allowedGroups: ["KPI_ROOT", "KPI_ADMIN", "KPI_USER", "KPI_CLIENTS"],
      description: "Moduł do zarządzania KPI",
      image: chartLineSolid,
      alt: "KPI",
    },
  ]

  const handleLogout = () => {
    fetch(port + "/logoutKpi", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res)
      .then((data) => {
        if (data.status === 200) {
          window.location.href = "/"
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const accept = () => {
    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    handleLogout()
  }

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    })
  }

  const confirmMessage = (
    <div>
      <p>Hello</p>
      <p>{user.user}</p>
      <p>Do you want to log out?</p>
    </div>
  )

  const confirm1 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: confirmMessage,
      icon: "pi pi-question-circle",
      acceptClassName: "p-button-danger",
      acceptIcon: "pi pi-sign-out",
      acceptLabel: "Log out",
      rejectLabel: "Cancel",
      accept,
      reject,
    })
  }

  function handleClick(link) {
    window.open(link, "_self")
  }

  return (
    <div className="App">
      <Toast ref={toast} />
      <>
        <ConfirmPopup />
        <Avatar
          label={user.user.split("")[0].toUpperCase()}
          className="mr-2 logout"
          size="large"
          style={{ backgroundColor: "#6c757d", color: "#ffffff" }}
          shape="circle"
          onClick={confirm1}
        />
      </>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="App-body">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {modules.map(
              (module, index) =>
                grupy.some((elem) => module.allowedGroups.includes(elem)) && (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Card
                      sx={{ maxWidth: 345, backgroundColor: "#f5f3d5" }}
                      className="card"
                      key={module.id}
                      onClick={() => handleClick(module.link)}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          sx={{ height: 140, objectFit: "contain" }}
                          image={module.image}
                          alt={module.alt}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {module.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {module.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => handleClick(module.link)}
                          sx={{ marginLeft: "auto" }}
                        >
                          Przejdź
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </div>
    </div>
  )
}
